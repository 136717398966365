

/*MY SPECIAL COLOR*/

.mycity {
  font-family: "Josefin Sans";
  letter-spacing: 5px;
  -webkit-text-stroke-width: 2px;
  -moz-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;
  -moz-text-stroke-color: white;
  color: transparent !important;
  text-transform: uppercase;
  text-decoration-thickness: 3px;
  /* margin-top: 15px;*/
}

.myspace {
  font-family: "Inter";
  letter-spacing: 5px;
  -webkit-text-stroke-width: 2px;
  -moz-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgb(40, 164, 241, 0.938);
  -moz-text-stroke-color: white;
  color: transparent !important;
  text-transform: uppercase;
  text-decoration-thickness: 3px;
  /*margin-top: 15px;*/
}

@keyframes typing {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.typing-animation {
  display: inline-block;
  overflow: hidden;
  animation: typing 2s steps(20);
  white-space: nowrap;
}

@keyframes typing-delayed {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.typing-animation-delayed {
  animation-delay: 2s;
}

.background-video-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 10;
}

.content2 {
  position: relative;
  z-index: 11;
  display: flex;
  align-items: start;
  justify-content: center;
 /* color: white!important;*/
  height: 100%;
  margin-top: 100px;
}

.points-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f0f0f0;
}

.point {
  position: absolute;
  background-color: blue;
  border-radius: 50%;
  transition: all 0.1s ease-out;
}
/*
.grid-container2 {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f0f0f0;
}*/

line {
  transition: all 0.1s ease-out;
}

